import axios from 'axios';
import { mapState } from 'vuex'

axios.defaults.baseURL = '/server/';
var session
let mxnBranches = {
    doGetbranch: ()=> {
        return axios.get('/branches',
        {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    doDelete: (id)=> {
        return axios.delete('/branch/' + id,
         {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    doCreate: (params)=> {
        return axios.post('/branch',
        params, {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    doUpdate: (params, id)=> {
        return axios.put('/branch/' + id,
        params, {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
}

export default mxnBranches;
