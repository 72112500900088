import axios from 'axios';

axios.defaults.baseURL = '/server/';
var session;
let mxnAudit = {
    doGetAuditLogs: (params)=> {
        return axios.post('/audit-logs',
        params,
        {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
}

export default mxnAudit;
