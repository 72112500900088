<template>
    <div class="w-full mt-12 pb-5">
        <p class="text-xl pb-3 flex items-center" v-if="gta.title">
            <i class="fas fa-list mr-3"></i> {{gta.title}}
        </p>
        <div class="relative">
            
            <div class="flex flex-col">
                <div class="overflow-x-auto shadow-sm sm:rounded-lg border">
                    <div class="inline-block min-w-full align-middle dark:bg-gray-800">
                        <div class="p-4" v-show="gta.search ? gta.search.show === undefined ? true : gta.search.show : true">
                            <label for="table-search" class="sr-only">Search</label>
                            <div class="relative mt-1">
                                <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                    <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path>
                                    </svg>
                                </div>
                                <input type="text" id="table-search" @keyup="doSearch" v-model="searchInput" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" :placeholder="gta.search ? gta.search.placeholder : 'Search here...' ">
                            </div>
                        </div>
                        <div class="overflow-auto">
                            <table class="min-w-full divide-y divide-gray-200 table-fixed dark:divide-gray-700">
                                <thead class="bg-gray-100 dark:bg-gray-700">
                                    <tr>
                                        <th v-for="(header, index) in gta.header" :key="index" scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                                            <div class="flex items-center th-cell select-none" @click="doSort(header.field, header.sortable)" :class="{'cursor-pointer' : header.sortable, 'pointer-events-none' : !header.sortable}">
                                                <span>{{header.name}}</span>
                                                <div class="flex flex-col ml-1" v-if="header.sortable">
                                                    <i class="fas fa-angle-up -mb-1" :class="{'text-blue-700': (!sorted.desc && sorted.field === header.field)}"></i>
                                                    <i class="fas fa-angle-down" :class="{'text-blue-700': (sorted.desc && sorted.field === header.field)}"></i>
                                               </div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
                                    <tr class="transition duration-300 ease-in-out hover:bg-gray-100" v-for="row in gta.data.list" :key="row.user_id">
                                        
                                        <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white" v-for="(body, index) in gta.body" :key="index">
                                            <span v-if="body.type === 'text'">{{row[body.name]}}</span>
                                            <span v-if="body.type === 'method'">{{body.fn(row)}}</span>
                                            <span v-if="body.type === 'date'">{{row[body.name] | date}}</span>
                                            <span v-if="body.type === 'currency'">{{row[body.name] | currency}}</span>
                                            
                                            <span v-if="body.actions">
                                                <i class="fas cursor-pointer inline-block text-lg ml-1 mr-1" 
                                                    v-show="action.show ? action.show(row) : true" 
                                                    v-for="(action, index) in body.actions" 
                                                    :key="index" 
                                                    :class="action.className" 
                                                    @click="action.fn(row)"></i>
                                            </span>
                                        </td>
                                    </tr>
                                    
                                </tbody>
                            </table>
                        </div>
                        <div class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
                            <div class="flex-1 flex justify-between sm:hidden" v-if="gta.data.list">
                                <a href="#" @click.prevent="doNavigate(gta.payload.page - 1)" :class="{'pointer-events-none' : gta.payload.page === 1}" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"> Previous </a>
                                <a href="#" @click.prevent="doNavigate(gta.payload.page + 1)" :class="{'pointer-events-none' : gta.payload.page >= gta.data.pages}" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"> Next </a>
                            </div>
                            <div v-if="gta.data.total === 0" class="text-sm text-gray-700 w-full text-center">
                                <p>No data found</p>
                            </div>
                            <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between" v-if="gta.data.list">
                                <div>
                                    <p class="text-xs text-gray-700">
                                        Showing
                                        <span class="font-medium">{{gta.payload.page > 1 ? ((gta.payload.page * gta.size - gta.size) + 1) : gta.payload.page}}</span>
                                        to
                                        <span class="font-medium">{{gta.payload.page === 1 ? gta.data.list.length : (gta.payload.page * gta.size - gta.size) + gta.data.list.length}}</span>
                                        of
                                        <span class="font-medium">{{gta.data.total}}</span>
                                        results
                                    </p>
                                </div>
                                <!--  <div class="text-sm text-gray-700 items-rigth">
                                    <div class="relative inline-flex sm:flex-1 sm:flex sm:items-center sm:justify-between">
                                        <span class="font-medium relative inline-flex whitespace-nowrap mr-3">Row per page:</span>
                                        <select
                                            class=" relative form-select form-select-sm appearance-none inline-flex w-full px-2 py-1 text-sm font-light text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                            aria-label=".form-select-sm example"
                                        >
                                            <option selected>10</option>
                                            <option value="1">20</option>
                                            <option value="2">50</option>
                                            <option value="3">100</option>
                                        </select>
                                    </div>
                                </div> -->
                                <div>
                                    <nav class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                                        <a href="#" @click.prevent="doNavigate(gta.payload.page - 1)" :class="{'pointer-events-none' : gta.payload.page === 1}" class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                                            <span class="sr-only">Previous</span>
                                            <!-- Heroicon name: solid/chevron-left -->
                                            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
                                            </svg>
                                        </a>
                                        <!-- Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" -->
                                        <!-- <a href="#" aria-current="page" class="z-10 bg-gray-200 border-gray-400 text-gray-500 relative inline-flex items-center px-4 py-2 border text-sm font-medium"> 1 </a>
                                        <a href="#" class="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"> 2 </a>
                                        <a href="#" class="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium"> 3 </a>
                                        <span class="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700"> ... </span>
                                        <a href="#" class="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium"> 8 </a>
                                        <a href="#" class="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"> 9 </a>
                                        <a href="#" class="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"> 10 </a> -->
                                        <a href="#" @click.prevent="doNavigate(gta.payload.page + 1)" :class="{'pointer-events-none' : gta.payload.page >= gta.data.pages}" class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                                            <span class="sr-only">Next</span>
                                            <!-- Heroicon name: solid/chevron-right -->
                                            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                            </svg>
                                        </a>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GenericTable',
    props: ['gta', 'search'],
    data() {
        return {
            searchInput: '',
            sorted: {
                field: '',
                decs: true
            }
        }
    },
    watch: {
        search: function(str) {
            this.searchInput = str;
        }
    },
    methods: {
        doNavigate(n) {
            let _this = this;
            _this.gta.payload.page = n || 1;
            _this.gta.query(_this.searchInput)
        },
        doSearch(e) {
            let _this = this;
            // console.info(e.keyCode)
            if (e.keyCode === 13) {
                // console.info(_this.searchInput);
                _this.gta.query(_this.searchInput)
            } else if (e.keyCode && _this.searchInput === '') {
                _this.gta.query();
            }
        },
        doSort(field, isSortable) {
            let _this = this;

            if (isSortable) {
                    
                if (_this.sorted.field === field) {
                    _this.sorted.desc = !_this.sorted.desc;
                } else {
                    _this.sorted.desc = true
                    _this.sorted.field = field
                }

                _this.gta.payload.sortField = field;
                _this.gta.payload.sortType = _this.sorted.desc ? 'DESC' : 'ASC';
                _this.gta.query(_this.searchInput);
            }

        }
        
    }
}
</script>