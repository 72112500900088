import axios from 'axios';
import { mapState } from 'vuex'

axios.defaults.baseURL = '/server/';
var session
let mxnCategories = {
    doGetCategory: ()=> {
        return axios.get('/categories',
        {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    doDelete: (id)=> {
        return axios.delete('/category/' + id,
         {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    doCreate: (params)=> {
        return axios.post('/category',
        params, {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    doUpdate: (params, id)=> {
        return axios.put('/category/' + id,
        params, {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
}

export default mxnCategories;
