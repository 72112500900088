<template>
    <div class="w-full m-auto text-center">
        <code v-if="device && config.deviceLabel">{{ device.label }}</code>
        <div class="border w-72 h-container-cam  m-auto relative flex justify-center" :class="{'hide-camera-icon' : isTakeShot }">
            <vue-web-cam
                v-if="isTakeShot"
                ref="webcam"
                :device-id="deviceId"
                @started="onStarted"
                @stopped="onStopped"
                @error="onError"
                @cameras="onCameras"
                @camera-change="onCameraChange"
            />
            <figure class="figure absolute top-0" v-show="!camOnShow || patientId">
                <!-- <span class="inline-block h-full align-middle"></span> -->
                <img :src="img" class="img-responsive max-w-full max-h-full absolute top-0 bottom-0 left-0 right-0 m-auto w-auto h-auto" />
            </figure>
        </div>

        <div class="row">
            <div class="w-full" v-show="isTakeShot">
                <!-- <button type="button" class="btn btn-primary border" @click="onCapture">Capture Photo</button> -->
                <i v-if="config.nav.capture" class="fas fa-camera cursor-pointer text-lg m-2 border pl-2 pr-2 text-blue-500" @click="onCapture" title="Capture Photo"></i>
                <i v-if="config.nav.stop"  class="fas fa-stop-circle cursor-pointer text-lg m-2 border pl-2 pr-2 text-red-500" @click="onStop"  title="Stop Camera"></i>
                <!-- <button type="button" class="btn btn-danger border" @click="onStop">Stop Camera</button> -->
                <i v-if="config.nav.play"  class="fas fa-play text-lg cursor-pointer m-2 border pl-2 pr-2 text-green-500" @click="onStart" title="Start Camera"></i>
                <i v-if="config.nav.retake"  class="fas fa-redo text-lg cursor-pointer m-2 border pl-2 pr-2" @click="onRetake" title="Retake Photo"></i>
                
            </div>
            <button type="button" v-show="!isTakeShot" class="mt-2 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:ring-gray-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600" @click="onTakeAShot">Take a shot</button>
        </div>
        <div class="w-full mt-3" v-if="config.selection && isTakeShot">
            <select v-model="camera" class="block m-auto bg-gray-100 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                <option value="">-- Select Device --</option>
                <option
                    v-for="device in devices"
                    :key="device.deviceId"
                    :value="device.deviceId"
                >{{ device.label }}</option>
            </select>
        </div>
    </div>
</template>

<script>
// import { WebCam } from "../../src";
import { WebCam } from "vue-web-cam";
export default {
    name: "App",
    props: ['isPopup', 'imageURL', 'patientId'],
    components: {
        "vue-web-cam": WebCam
    },
    data() {
        return {
            img: null,
            camOnShow: true,
            isTakeShot: false,
            camera: '',
            deviceId: null,
            devices: [],
            config: {
                nav: {
                    capture: true,
                    stop: false,
                    play: false,
                    retake: true
                },
                selection: true,
                deviceLabel: false
            }
        };
    },
    computed: {
        device: function() {
            return this.devices.find(n => n.deviceId === this.deviceId);
        }
    },
    watch: {
        camera: function(id) {
            this.deviceId = id;
        },
        devices: function() {
            // Once we have a list select the first one
            const [first, ...tail] = this.devices;
            if (first) {
                this.camera = first.deviceId;
                this.deviceId = first.deviceId;
            }
        },
        isPopup: function(d) {
            console.info('isPopup', d)
            if (!d) {
                this.img = null;
                this.camera = null;
                this.isTakeShot = d;

            }
        },
        imageURL: function(d) {
            if (this.patientId) {
                this.img = d
                this.isTakeShot = true;
                this.camOnShow = false;
            }
        }
    },
    methods: {
        onCapture() {
            this.img = this.$refs.webcam.capture();
            this.$emit('photo', this.img)
            this.camOnShow = false;
            this.onStop();
        },
        onStarted(stream) {
            console.log("On Started Event", stream);
        },
        onStopped(stream) {
            console.log("On Stopped Event", stream);
        },
        onStop() {
            this.$refs.webcam.stop();
        },
        onStart() {
            this.$refs.webcam.start();
        },
        onError(error) {
            console.log("On Error Event", error);
        },
        onCameras(cameras) {
            this.devices = cameras;
            console.log("On Cameras Event", cameras);
        },
        onCameraChange(deviceId) {
            this.deviceId = deviceId;
            this.camera = deviceId;
            console.log("On Camera Change Event", deviceId);
        },
        onRetake() {
            this.camOnShow = true;
            this.img = null;
            // this.camera = null;
            this.onStop();
            this.onStart();
        },
        onTakeAShot() {
            this.isTakeShot = true;
            // this.$refs.webcam.start();
        },
    },
    mounted() {
        // console.info('mounted',this.isPopup)
        console.info('this.patientId',this.patientId)
        // console.info('this.imageURL',this.imageURL)
        if (this.patientId) {
            // this.isTakeShot = true;
            // this.camOnShow = false;
            this.img = this.imageURL;
        }
    }
};
</script>