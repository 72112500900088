import mxnDepartments from '@/mixins/departments'
import mxnSwal from '@/mixins/toast'
import mxnCommon from '@/mixins/common'
import moment from 'moment';

export default {
    name: 'Departments',
    mixins: [mxnDepartments, mxnSwal, mxnCommon],
    data() {
        return {
            departmentId: '',
            department: '',
            isOpen: false,
            // gta
            deptList: [],
        }
    },
    methods: {
        preLoader(act) {
            this.$store.dispatch('doPreLoader',act)
        },
        getDepartmentList(str) {
            let _this = this;
            // TODO: preloader
            _this.preLoader(true);

            mxnDepartments.doGetDepartmentList().then(r => {
                _this.deptList = r.data
                // Update global list
                _this.$emit('update-departments', r.data)
                // TODO: end preloader
                setTimeout(() => {
                    _this.preLoader(false);
                }, 100)
            }, e => {
                console.info(e)
            })
        },
        saveDepartmentForm(s) {
            let _this = this;
            // return
            let params = {
                "department_name": _this.department,
            }
            
            // TODO: preloader
            _this.preLoader(true);
            if (_this.departmentId) {
                // Create user
                mxnDepartments.doUpdateDepartment(params, _this.departmentId).then(r => {
                    let d = r.data;
                    mxnSwal.toast('success', d)
                    _this.viewForm()
                    // TODO: reload
                    _this.getDepartmentList()
                }, e => {
                    console.info(e)
                    mxnSwal.toast('error', e)
                    _this.preLoader(true);
                });

                return false;
            }
            // Create user
            mxnDepartments.doCreateDepartment(params).then(r => {
                let d = r.data;
                mxnSwal.toast('success', d)
                _this.viewForm()
                // TODO: reload
                _this.getDepartmentList()
            }, e => {
                console.info(e)
                mxnSwal.toast('error', e)
                _this.preLoader(true);
            })

        },
        doSubmitForm() {
            this.$refs.submitDeptForm.click()
        },
        doClearForm() {
            let _this = this;
            _this.departmentId = '';
            _this.department = '';
        },
        removeDepartment(data,i) {
            let index = i;
            let _this = this;
            mxnSwal.confirmation({
                icon: 'warning',
                title: "Are you sure you want to remove this '" + data.department_name + "'?",
                msg: "This action can't be undone",
                confirmed: () => {
                    _this.preLoader(true);
                    mxnDepartments.doDeleteDepartment(data.department_id).then(r => {
                        let d = r.data;
                        mxnSwal.toast('success', d)
                        // TODO: remove element
                        _this.$delete(_this.deptList, index)
                        setTimeout(() => {
                            _this.preLoader(false);
                        }, 100)
                    }, e => {
                        console.info(e)
                        mxnSwal.toast('error', e)
                        _this.preLoader(true);
                    })
                }
            } )
        },
        updateDepartment(d) {
            let _this = this;
            _this.viewForm();
            _this.departmentId = d.department_id
            _this.department = d.department_name
        },
        viewForm() {
            let _this = this;
            _this.doClearForm();
            _this.isOpen = !_this.isOpen
            // _this.$emit('doView')
        }
    },
    mounted () {
        let _this = this;
        // TODO: load department list
        _this.getDepartmentList();

    }
}