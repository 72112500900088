import Vue from 'vue'
import VueRouter from 'vue-router'

// components
import Users from '../views/Users'
import Patients from '../views/Patients'
import Configuration from '../views/Configuration'
import Audit from '../views/Audit'
import PageNotFound from '../views/PageNotFound'
import Login from '@/components/Login'
import Dashboard from '@/components/Dashboard'
import Account from '@/components/Account'

import mxnAuth from '@/mixins/auth'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    alias: '/dashboard',
    // name: 'Dashboard',
    component: Dashboard,
    children: [
      { path: '/', redirect: { name: 'Users', meta: {access: ['ADMIN']} } },
      { path: '/users', name: 'Users', component: Users, meta: {access: ['ADMIN']} },
      { path: '/patients', name: 'Patients', component: Patients, meta: {access: ['FRONTDESK', 'BILLING', 'PATIENT', 'DIAGNOSTIC', 'RELEASING']} },
      { path: '/configuration', name: 'Configuration', component: Configuration, meta: {access: ['ADMIN']} },
      { path: '/audit', name: 'Audit', component: Audit, meta: {access: ['ADMIN']} },
      { path: '/account', name: 'Account', component: Account, meta: {access: ['ADMIN','FRONTDESK','BILLING','PATIENT','DIAGNOSTIC', 'RELEASING']} },
    ],
    beforeEnter: (to, from, next) => {
        // console.info("load dashboard", this)
        // console.info(to, from)
        // // Check Session First
        // console.info(mxnAuth);

        if (from.name !== 'Login' && to.redirectedFrom != '/dashboard') {
          checkSession(to, from, next)
        } else {
          next()
        }

    }
  },
  {
    path: '/login',
    // alias: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: (to, from, next) => {
      let _session = localStorage.getItem("_SESSION");
      if (_session != null) {
        checkSession(to, from, next, '/dashboard');
      } else {
        next();
      }
      
    }
  },
  {
    // TODO: when none of the above matches
    path: '*',
    // alias: '/404',
    name: 'PageNotFound',
    component: PageNotFound
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const checkSession = (to, from, next, path) => {
  let _session = localStorage.getItem("_SESSION");
  // console.info(path)
  mxnAuth.doCheckSession(_session).then( r => {
    // console.log("Check session", r);
    let data = r.data;
    let meta = to.meta;

    // console.info(to)
    // console.info(meta.access, data.user_type)
    // console.info(path)

    // TODO: store user data to vuex
    router.app.$store.dispatch("doStoreData", data)

    // TODO: redirection security
    let patientAccess = ['FRONTDESK', 'BILLING', 'PATIENT', 'DIAGNOSTIC', 'RELEASING']
    let fromHome = to.redirectedFrom === '/' || to.name === 'Login'
    if ((meta.access && meta.access.indexOf(data.user_type) >= 0)) {
      next(path);
    } else if (patientAccess.indexOf(data.user_type) >= 0 && fromHome){
      next('/patients');
    } else if (data.user_type === 'ADMIN'){
      next(path)
    } else {
      next('/404')
    }
    
    
  }, e => {
      //swal("Could not log in!", "Please ensure your ID and Password", "error");
      console.log("ERROR Login", e.response);
      // TODO: apply clear session
      localStorage.clear();
      // TODO: redirect to login
      // window.location.href = '/login';
      next('/login');

  });
}

router.beforeEach((to, from, next) => {
  // console.info("to ",to)
  // console.info("From ",from)
  let _session = localStorage.getItem("_SESSION");
  if (from.name === "Login" && _session != null)
    next();
  else if (to.name !== "Login" && _session === null){
    next({path: "/login"})
  } 
  // Jump to login
  next();
})



export default router
