import _ from 'lodash'
import mxnAuth from '@/mixins/auth'
import mxnSwal from '@/mixins/toast'
// import swal from 'vue-sweetalert2'

export default {
    name: 'Login',
    mixins: [mxnAuth, mxnSwal],
    data() {
        return {
            isForgot: false,
            hasOTP: false,
            isPwd: "password",
            username: '',
            password: '',
            email: '',
            buttonTitle: 'Login',
            isProcessing: false,
            verificationEmail: ''
        } 
    },
    created() {
        /* console.info(process.env);
        // console.info(mxnAuth.created());
        console.info(this.$cookies)
        console.info(this.$router)
        // this.$router.go("dashboard") */
        
      },
    methods: {
        viewForgotPasswordSection(event) {
            event.preventDefault();
            this.isForgot = !this.isForgot;
          console.info(event)
        },
  
        doLogin(e) {
            let _this = this;
            // _this.$router.push("dashboard")
            // return
            _this.buttonTitle = "Processing..."
            _this.isProcessing = true;


            mxnAuth.doAuth(_this.username, _this.password).then( r => {
                console.log("LOGIN Response", r);
                let data = r.data;

                // TODO: apply session
                localStorage.setItem("_SESSION", data.session);

                // TODO: store date to vuex
                this.$store.dispatch("doStoreData", data)
                // TODO: update vuex session
                this.$store.dispatch("setSession", data.session)
                // console.info('INFO: ',data)

                if (data.user_otp_enabled === '1') {
                    _this.verificationEmail = data.user_email
                    _this.doSendOTP(data.user_email);
                    _this.hasOTP = true;
                    mxnSwal.toast('success', 'Signed in successfully!')
                    return;
                }

                mxnSwal.toast('success', 'Signed in successfully!')
                let patientAccess = ['FRONTDESK', 'BILLING', 'PATIENT', 'DIAGNOSTIC', 'RELEASING']
                if (data.user_type === 'ADMIN') {
                    _this.$router.push("dashboard")
                } else if (patientAccess.indexOf(data.user_type) >= 0) {
                    _this.$router.push("patients")
                }
                
                console.info("redirecting to dashboard")
            }, e => {
                //swal("Could not log in!", "Please ensure your ID and Password", "error");
                console.log("ERROR Login", e.response);
                mxnSwal.toast('error', e.response.data)
                this.buttonTitle = "Login"
                this.isProcessing = false;

            });
            
        },
        doGetOTP(otp) {
            let _this = this;
            console.info(otp)
            mxnAuth.doValidateOTP(otp).then( r => {
                console.log("Validate OTP", r);

            }, e => {
                //swal("Could not log in!", "Please ensure your ID and Password", "error");
                console.log("ERROR otp", e.response);

            });
        },
        doSendOTP(email) {
            let _this = this;
            // console.info(otp)
            mxnAuth.doGetOTP(email).then( r => {
                console.log("get OTP via email", r);

            }, e => {
                //swal("Could not log in!", "Please ensure your ID and Password", "error");
                console.log("ERROR otp", e.response);

            });
        },
        doCancelOTP() {
            let _this = this;
            _this.hasOTP = false
            this.buttonTitle = "Login"
            this.password = ''
            this.isProcessing = false;
        },
        submitForgotPassword(e) {
            let _this = this;
            mxnAuth.forgotPassword(_this.email).then( r => {
                console.log("Forgot password Response", r);
                mxnSwal.toast('success', r.data);
                this.email = "";
                this.isForgot = false;
            }, e => {
                //swal("Could not log in!", "Please ensure your ID and Password", "error");
                console.log("ERROR forgot password", e.response);
                mxnSwal.toast('error', e.response.data);

            });
        },

        OTPInput() {
            let _this = this;
            const inputs = document.querySelectorAll('#otp > *[type]');
            for (let i = 0; i < inputs.length; i++) {
                inputs[i].addEventListener('keydown', function(event) {
                    if (event.key === "Backspace") {
                        inputs[i].value = '';
                        if (i !== 0) inputs[i - 1].focus();
                    } else {
                        if (i === inputs.length - 1 && inputs[i].value !== '') {
                            return true;
                        } else if (event.keyCode > 47 && event.keyCode < 58) {
                            inputs[i].value = event.key;
                            if (i !== inputs.length - 1) inputs[i + 1].focus();
                            event.preventDefault();
                        } else if (event.keyCode > 64 && event.keyCode < 91) {
                            inputs[i].value = String.fromCharCode(event.keyCode);
                            if (i !== inputs.length - 1) inputs[i + 1].focus();
                            event.preventDefault();
                        }
                    }
                    let otp = '';
                    Array.prototype.forEach.call(inputs, e => {
                        otp += e.value;
                        if (otp.length === 6){
                            _this.doGetOTP(otp)
                        }
                    })
                });
            }
        }
    },
      updated() {
          this.OTPInput()
      }
  }