import mxnBranches from '@/mixins/branches'
import mxnSwal from '@/mixins/toast'
import mxnCommon from '@/mixins/common'
import moment from 'moment';

export default {
    name: 'Branches',
    mixins: [mxnBranches, mxnSwal, mxnCommon],

    data() {
        return {
            branchId: '',
            branch: '',
            isOpen: false,
            // gta
            branchList: [],
        }
    },
    methods: {
        preLoader(act) {
            this.$store.dispatch('doPreLoader',act)
        },
        getBranchList(str) {
            let _this = this;
            // TODO: preloader
            _this.preLoader(true);

            mxnBranches.doGetbranch().then(r => {
                _this.branchList = r.data
                // Update global list
                _this.$emit('update-branches', r.data)
                // TODO: end preloader
                setTimeout(() => {
                    _this.preLoader(false);
                }, 100)
            }, e => {
                console.info(e)
            })
        },
        saveBranchForm(s) {
            let _this = this;
            // return
            let params = {
                "branch_name": _this.branch,
            }
            
            // TODO: preloader
            _this.preLoader(true);
            if (_this.branchId) {
                // Create user
                mxnBranches.doUpdate(params, _this.branchId).then(r => {
                    let d = r.data;
                    mxnSwal.toast('success', d)
                    _this.viewForm()
                    // TODO: reload
                    _this.getBranchList()
                }, e => {
                    console.info(e)
                    mxnSwal.toast('error', e)
                    _this.preLoader(true);
                });

                return false;
            }
            // Create user
            mxnBranches.doCreate(params).then(r => {
                let d = r.data;
                mxnSwal.toast('success', d)
                _this.viewForm()
                // TODO: reload
                _this.getBranchList()
            }, e => {
                console.info(e)
                mxnSwal.toast('error', e)
                _this.preLoader(true);
            })

        },
        doSubmitForm() {
            this.$refs.submitDeptForm.click()
        },
        doClearForm() {
            let _this = this;
            _this.branchId = '';
            _this.branch = '';
        },
        removeBranch(data,i) {
            let index = i;
            let _this = this;
            mxnSwal.confirmation({
                icon: 'warning',
                title: "Are you sure you want to remove this '" + data.branch_name + "'?",
                msg: "This action can't be undone",
                confirmed: () => {
                    _this.preLoader(true);
                    mxnBranches.doDelete(data.branch_id).then(r => {
                        let d = r.data;
                        mxnSwal.toast('success', d)
                        // TODO: remove element
                        _this.$delete(_this.branchList, index)
                        setTimeout(() => {
                            _this.preLoader(false);
                        }, 100)
                    }, e => {
                        console.info(e)
                        mxnSwal.toast('error', e)
                        _this.preLoader(true);
                    })
                }
            } )
        },
        updateBranch(d) {
            let _this = this;
            _this.viewForm();
            _this.branchId = d.branch_id
            _this.branch = d.branch_name
        },
        viewForm() {
            let _this = this;
            _this.doClearForm();
            _this.isOpen = !_this.isOpen
        }
    },
    mounted () {
        let _this = this;
        // TODO: load branch list
        _this.getBranchList();

    }
}