import axios from 'axios';

axios.defaults.baseURL = '/server/';
var session;
let mxnUser = {
    doGetUsers: (params)=> {
        // let session = localStorage.getItem('_SESSION');
        return axios.post('/users',
        params, {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    doCreateUser: (params)=> {
        return axios.post('/user',
        params, {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    doUpdateUser: (params, id)=> {
        return axios.put('/user/' + id,
        params, {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    updateUserPassword: (userId, params) => {
        return axios.put('/user/password/' + userId,
        params, {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    deactivateUserStatus: (userId) => {
        return axios.delete('/user-status/' + userId,
        {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    activateUserStatus: (userId) => {
        return axios.put('/user-status/' + userId,{},
        {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
}

export default mxnUser;
