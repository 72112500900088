<template>
    <footer class="w-full bg-white text-right p-4 text-xs">
        Powered by <a target="_blank" href="https://variantcode.com" class="underline">Variant Code</a>.
    </footer>
</template>

<script>
export default {
    name: 'Footer'
}
</script>