import axios from 'axios';
import { mapState } from 'vuex'

axios.defaults.baseURL = '/server/';
var session
let mxnAppointments = {
    getAppointmentsById: (params, id)=> {
        // let session = localStorage.getItem('_SESSION');
        return axios.post('/appointments/' + id,
        params, {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    viewAppointment: (id)=> {
        return axios.get('/appointment/' + id,
         {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    createAppointment: (params)=> {
        return axios.post('/appointment',
        params, {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    updateAppointment: (params, id)=> {
        return axios.put('/appointment/' + id,
        params, {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    closeAppointment: (id)=> {
        return axios.delete('/appointment/' + id,
         {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    updateAppointmentPayment: (params, id)=> {
        return axios.post('/appointment/' + id,
        params, {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
}

export default mxnAppointments;
