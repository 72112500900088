import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';

axios.defaults.baseURL = '/server/';

Vue.use(Vuex)

// TODO: handle state
const defaultState = () => {
	return {
		userInfo: {},
		patientInfo: {},
		isPreLoader: false,
		session: localStorage.getItem('_SESSION'),
		postDMResponse: [],
		getAttachement: [],
		uploadPercentage: 0
	}
}

// TODO: state
const state = defaultState

// TODO: handle mutations
const mutations = {
	preLoader (state, action) {
		state.isPreLoader = action
	},
	storeInfo (state, info) {
		state.userInfo = info
	},
	storePatientInfo (state, info) {
		state.patientInfo = info
	},
	resetState (state) {
		Object.assign(state, defaultState())
	},
	storeSession (state, session) {
		state.session = session
	},

	// upload file mutation
	postDMS(state, response) {
        state.postDMResponse = response
    },
	getDMS(state, response) {
        state.getAttachement = response
    },
	removeAttachment(state, id) {
		const i = state.getAttachement.map(item => item.dms_id).indexOf(id);
		state.getAttachement.splice(i, 1);
	}
}

// TODO: handle actions
const actions = {
	doStoreData({commit}, data) {
		commit('storeInfo', data)
	},
	doStorePatient({commit}, data) {
		commit('storePatientInfo', data)
	},
	doPreLoader({commit}, data) {
		commit('preLoader', data)
	},
	resetStateDetails({commit}) {
		commit('resetState')
	},
	// TODO: for user first login/relogin
	setSession({commit}, session) {
		commit('storeSession', session)
	},

	// Upload file action
	uploadResultFile({commit, state, dispatch}, params) {
		let id = params.get('dms_transactionid');
		return axios.post('/dms',
			params,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
					"Authorization" : state.session,
				},
				onUploadProgress: function( progressEvent ) {
					state.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
				}.bind(this)
			})
			.then(response => {
				commit('postDMS', response.data)
				setTimeout(() => state.uploadPercentage = 0, 1000)
				dispatch('getAttachement', id)
			});
	},
	getAttachement({commit, state}, id) {
		// TODO: remove current content
		state.getAttachement = []
		return axios.get('/dms-transaction/' + id,
			{
				headers: {
					"Authorization" : state.session,
				}
			})
			.then(response => {
				commit('getDMS', response.data)
			});
	},
	removeUploadedFile({commit, state}, id) {
		return axios.delete('/dms/' + id,
			{
				headers: {
					"Authorization" : state.session,
				}
			}).then(response => {
				commit('removeAttachment', id)
			});
	},
	downloadUploadedFile({commit, state}, param) {
		return axios.get('/dms/' + param.dms_id,
			{
				responseType: 'blob',
				headers: {
					"Authorization" : state.session,
				}
			})
			.then(response => {
				let headerLine = response.headers['content-disposition'];
				let startFileNameIndex = headerLine.indexOf('"') + 1
				let endFileNameIndex = headerLine.lastIndexOf('"');
				let filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
				let fileURL = window.URL.createObjectURL(new Blob([response.data]));
				let fileLink = document.createElement('a');
			 
				fileLink.href = fileURL;
				fileLink.setAttribute('download', filename );
				document.body.appendChild(fileLink);
			  
				fileLink.click();
			});
	},
}

// TODO: handle getters
const getters = {
	isPreLoader : state => {
			return state.isPreLoader
	},
	userInfo : state => {
			return state.userInfo
	},
	patientInfo : state => {
			return state.patientInfo
	},
	getUserFullName : state => {
		return state.userInfo.user_firstname + " " + state.userInfo.user_lastname;
	},
	isFrontdesk : state => {
		return state.userInfo.user_type === 'FRONTDESK';
	},
	isPatient : state => {
		return state.userInfo.user_type === 'PATIENT';
	},
	isBilling : state => {
		return state.userInfo.user_type === 'BILLING';
	},
	isDiagnostic : state => {
		return state.userInfo.user_type === 'DIAGNOSTIC';
	},
	isReleasing : state => {
		return state.userInfo.user_type === 'RELEASING';
	},
	isAdmin : state => {
		return state.userInfo.user_type === 'ADMIN';
	},

	// upload
	uploadProgress : state => {
		return state.uploadPercentage
	},
	getAttachement: state => {
		return state.getAttachement
	}

}

// TODO: handle modules
const modules = {}
export default new Vuex.Store({
	state,
    getters,
    actions,
    mutations,
	modules
})
