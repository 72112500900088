import axios from 'axios';

axios.defaults.baseURL = '/server/';
var session;
let mxnCommon = {
    doGetCountries: ()=> {
        return axios.get('/countries',
        {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
}

export default mxnCommon;
