import axios from 'axios';
import { mapState } from 'vuex'

axios.defaults.baseURL = '/server/';
var session
let mxnDepartments = {
    doGetDepartmentList: ()=> {
        return axios.get('/departments',
        {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    doDeleteDepartment: (id)=> {
        return axios.delete('/department/' + id,
         {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    doCreateDepartment: (params)=> {
        return axios.post('/department',
        params, {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    doUpdateDepartment: (params, id)=> {
        return axios.put('/department/' + id,
        params, {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
}

export default mxnDepartments;
