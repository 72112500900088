import axios from 'axios';
import { mapState } from 'vuex'

axios.defaults.baseURL = '/server/';
var session
let mxnPatients = {
    doGetPatients: (params)=> {
        // let session = localStorage.getItem('_SESSION');
        return axios.post('/patients',
        params, {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    doViewPatient: (id)=> {
        return axios.get('/patient/' + id,
         {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    doCreatePatient: (params)=> {
        return axios.post('/patient',
        params, {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    doUpdatePatient: (params, id)=> {
        return axios.put('/patient/' + id,
        params, {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
}

export default mxnPatients;
