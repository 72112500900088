import mxnPatients from '@/mixins/patients'
import mxnDate from '@/mixins/date'
import mxnSwal from '@/mixins/toast'
import mxnCommon from '@/mixins/common'
import { mapState, mapGetters } from 'vuex'
import moment from 'moment';
import WebCam from './../WebCam'
import GenericTable from './../GenericTable'
import Appointments from './../Appointments'

export default {
    name: 'Patient',
    mixins: [mxnPatients, mxnSwal, mxnCommon, mxnDate],
    props: {
        isPopup: Boolean,
        isOpen: Boolean
    },
    computed: {
        ...mapState([ 'userInfo', 'patientInfo']),
        ...mapGetters(['isFrontdesk', 'isPatient', 'isAdmin']),
        countryName() {
          let countryObj = this.countries.filter((c)=>{
            return c.country_id == this.patientInfo.patient_countryid;
          });

          return countryObj[0]?.country_name;
        }
    },
    data() {
        return {
            dropDown: false,
            showModalPatient: false,
            dateFormatter: "dd/MM/yyyy",
            isShowPatientForm: false,
            isShowPatientDetails: false,
            patientList: [],
            typeList: ['ADMIN','FRONTDESK','DIAGNOSTIC','BILLING','RELEASING','DOCTOR','PATIENT'],
            civilStatusList: ['Single','Married','Divorced','Separated','Widowed'],
            countries: [],
            department: [],
            referenceId: '',
            patientId: '',
            firstName: '',
            lastName: '',
            middleName: '',
            photo: '',
            birthdate: '',
            gender: '',
            civilStatus: '',
            address: '',
            barangay: '',
            city: '',
            province: '',
            zipcode: '',
            email: '',
            mobile: '',
            phone: '',
            country: '164',
            // gta
            searchInput: '',
            gta: {
                title: 'Patient List',
                search: {
                    placeholder: 'Reference No., First Name or Last Name'
                },
                header: [
                    {name: "Reference Number"},
                    {name: "First Name", sortable: true, field: 'patient_firstname'},
                    {name: "Last Name", sortable: true, field: 'patient_lastname'},
                    {name: "Date Registered"},
                    {name: "Action"},
                ],
                body: [
                    {name: "patient_referenceid", type: 'text'},
                    {name: "patient_firstname", type: 'text'},
                    {name: "patient_lastname", type: 'text'},
                    {name: "patient_created", type: 'date'},
                    {actions: [
                        {
                            className: 'fa-user-edit text-blue-400',
                            fn: (row) => {
                                this.doGetInfo(row, 'update')
                            },
                            show: () => {
                                return this.userInfo.user_type === 'FRONTDESK'
                            }
                        },
                        {
                            className: 'fa-info-circle text-blue-400',
                            fn: (row) => {
                                this.doGetInfo(row, 'view')
                            }
                        },
                    ]},
                ],
                query: this.getPatientList,
                data: {},
                size: 10,
                payload: {
                    page: 1,
                    sortField: "patient_lastname",
                    sortType: "ASC",
                    search: this.searchInput
                }
            }
        }
    },
    methods: {
        preLoader(act) {
            this.$store.dispatch('doPreLoader',act)
        },
        getPatientList(str) {
            let _this = this;
            let params = _this.gta.payload;
            params.search = str || '';
            _this.searchInput = params.search;

            // TODO: prevent search on empty string
            if ((str === '' || str === undefined) && !_this.isAdmin) {
                _this.gta.data = {};
                return
            }

            // TODO: preloader
            _this.preLoader(true);

            mxnPatients.doGetPatients(params).then(r => {
                _this.gta.data = r.data

                if (_this.gta.data.total === 0 && _this.searchInput !== '') {
                    mxnSwal.toast('info', "No patient record found.")
                }
                // TODO: end preloader
                setTimeout(() => {
                    _this.preLoader(false);
                }, 100)
            }, e => {
                console.info(e)
            })
        },
        getCountries() {
            let _this = this;
            mxnCommon.doGetCountries().then(r => {
                // console.info("countries List: ", r.data)
                _this.countries = r.data
            }, e => {
                console.info(e)
            })
        },
        saveUserForm(s) {
            let _this = this;
            // console.info("email:", _this.email)
            // return
            let params = {
                "patient_firstname": _this.firstName,
                "patient_middlename": _this.middleName,
                "patient_lastname": _this.lastName,
                "patient_photo": _this.photo,
                "patient_birthdate": _this.birthdate,
                "patient_gender": _this.gender,
                "patient_marital": _this.civilStatus,
                // "patient_countryid": _this.country,
                "patient_countryid": 164, // Default to Philippines
                "patient_address": _this.address,
                "patient_city": _this.city,
                "patient_province": _this.province,
                "patient_barangay": _this.barangay,
                "patient_zipcode": _this.zipcode,
                "patient_phone": _this.phone,
                "patient_mobile": _this.mobile,
                "timestamp": moment().toDate().getTime()
            }

            // _this.userForm();
            // TODO: preloader
            _this.preLoader(true);
            if (_this.patientId) {
                params.patient_userid = _this.patientUserId
                // Create user
                mxnPatients.doUpdatePatient(params, _this.patientId).then(r => {
                    console.info("User List: ", r.data)
                    let d = r.data;
                    mxnSwal.toast('success', d)
                    _this.viewForm();
                    // TODO: reload
                    _this.getPatientList(_this.searchInput);

                    // TODO: show details
                    _this.doGetInfo({}, 'view')
                }, e => {
                    console.info(e)
                    mxnSwal.toast('error', e)
                    _this.preLoader(true);
                });

                return false;
            }
            // Create user
            mxnPatients.doCreatePatient(params).then(r => {
                console.info("User List: ", r.data)
                let d = r.data;
                mxnSwal.toast('success', d.message)
                _this.viewForm();
                // TODO: reload
                _this.getPatientList(_this.searchInput)

                // TODO: get ref
                _this.referenceId = d.patient_referenceid;
                // TODO: show details
                _this.doGetInfo({}, 'view')
            }, e => {
                console.info(e)
                mxnSwal.toast('error', e)
                _this.preLoader(true);
            })

        },
        doSubmitForm() {
            this.$refs.submitUserForm.click()
        },
        doClearForm() {
            let _this = this;
            _this.patientId = '';
            _this.firstName = '';
            _this.lastName = '';
            _this.middleName = '';
            _this.photo = '';
            _this.birthdate = '';
            _this.gender = '';
            _this.civilStatus = '';
            _this.barangay = '';
            _this.address = '';
            _this.city = '';
            _this.province = '';
            _this.zipcode = '';
            // _this.email = '';
            _this.mobile = '';
            _this.phone = '';
            _this.country = '';

            this.$store.dispatch('doStorePatient', {})
        },
        doGenerateDetails() {
            // console.info(row)
            let _this = this;
            let details = _this.patientInfo;
            _this.patientUserId = details.patient_userid;
            _this.patientId = details.patient_id;
            _this.firstName = details.patient_firstname;
            _this.lastName = details.patient_lastname;
            _this.middleName = details.patient_middlename;
            _this.photo = details.patient_photo;
            _this.birthdate = details.patient_birthdate;
            _this.gender = details.patient_gender;
            _this.civilStatus = details.patient_marital;
            _this.barangay = details.patient_barangay;
            _this.address = details.patient_address;
            _this.city = details.patient_city;
            _this.province = details.patient_province;
            _this.zipcode = details.patient_zipcode;
            // _this.email = details.email;
            _this.mobile = details.patient_mobile;
            _this.phone = details.patient_phone;
            _this.country = details.patient_countryid;

            // _this.civilStatus = row.user_id;

            _this.$emit('doView')
            _this.isShowPatientForm = true;

        },
        doGetInfo(row, action) {
            // console.info(row)
            let _this = this;
            // this.patientInfo = row
            _this.referenceId = row.patient_referenceid ? row.patient_referenceid : _this.referenceId;
            // TODO: preloader
            _this.preLoader(true);
            mxnPatients.doViewPatient(_this.referenceId).then(r => {
                console.info("User List: ", r.data)
                let d = r.data;
                // _this.patientInfo = d;
                this.$store.dispatch('doStorePatient',d)

                if (action === 'update') {
                    _this.doGenerateDetails()
                } else if (action === 'view' && _this.isAdmin) {
                    _this.showModalPatient = true
                } else if (action === 'view') {
                    _this.isShowPatientDetails = true
                }

                // mxnSwal.toast('success', d)
                setTimeout(() => {
                    // TODO: generate input date
                    mxnDate.inputDate("input-date")

                    _this.preLoader(false);
                }, 100)
            }, e => {
                console.info(e)
                mxnSwal.toast('error', e)
                _this.preLoader(false);

            });
        },
        validateEmail(value) {
            // if the field is empty
            if (!value) {
                return 'This field is required';
            }
            // if the field is not a valid email
            const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
            if (!regex.test(value)) {
                return 'This field must be a valid email';
            }
            // All is good
            return true;
        },
        selectText(node) {
            let text = node.target.innerHTML;
            console.info(node, text)
            // document.execCommand('copy')
            // return
            if (window.clipboardData && window.clipboardData.setData) {
                // TODO: notify user
                mxnSwal.toast('success', 'Copied to clipboard!')
                // IE specific code path to prevent textarea being shown while dialog is visible.
                return clipboardData.setData("Text", text);

            } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
                var textarea = document.createElement("textarea");
                textarea.textContent = text;
                textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in MS Edge.
                document.body.appendChild(textarea);
                textarea.select();
                // TODO: notify user
                mxnSwal.toast('success', 'Copied to clipboard!')
                try {
                    return document.execCommand("copy");  // Security exception may be thrown by some browsers.
                } catch (ex) {
                    console.warn("Copy to clipboard failed.", ex);
                    return false;
                } finally {
                    document.body.removeChild(textarea);
                }
            }
        },
        viewForm(v) {
            let _this = this;
            _this.doClearForm();

            if (v === 'new') {
                _this.searchInput = ''
                _this.gta.data = {};
                _this.isShowPatientForm = true;
                _this.isShowPatientDetails = false
            } else {
                _this.isShowPatientForm = !_this.isShowPatientForm
            }
            // TODO: init date
            setTimeout(function() {mxnDate.inputDate("input-date")})

        },
        doSearch(e) {
            let _this = this;
            // console.info(e.keyCode)
            if (e.keyCode === 13 && _this.searchInput !== '') {
                // console.info(_this.searchInput);
                _this.getPatientList(_this.searchInput)
            } else if (e.keyCode && _this.searchInput === '') {
                _this.gta.data = [];
            }
        },
        getPhotoBase64(data) {
            this.photo = data;
        },
        closeViewDetail() {
            let _this = this;
            if (_this.isAdmin) {
                _this.showModalPatient = false
            } else {
                _this.isShowPatientDetails = false
            }

            this.$store.dispatch('doStorePatient',{})
            _this.referenceId = ''
        },
        getAge(bDate) {
            let d = new Date
            return d.getFullYear() - parseInt(bDate?.split("/")[2]);
        }
    },
    mounted () {
        let _this = this;
        // TODO: get department
        // _this.getDepartment()

        if (_this.isAdmin) {
            // TODO: load patientList
            _this.getPatientList();
        } else if (_this.isFrontdesk) {
            // TODO: get countries

        } else if (_this.isPatient) {
            console.info({patient_referenceid: _this.userInfo.user_email})
            let p = {patient_referenceid: _this.userInfo.user_email};
            _this.doGetInfo(p, 'view')
        }
        // console.info(_this.patientInfo)

        _this.getCountries();

    },
    components: {
        WebCam,
        GenericTable,
        Appointments
    }
}
