
import axios from 'axios';
import { mapState } from 'vuex'

axios.defaults.baseURL = '/server/';
var session
let mxnTransactionTypes = {
    getTransactionTypesById: (params, id)=> {
        // let session = localStorage.getItem('_SESSION');
        return axios.post('/transaction-types/' + id,
        params, {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    getTransactionTypesByBranchId: (params)=> {
        // let session = localStorage.getItem('_SESSION');
        return axios.post('/transaction-types',
        params, {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    getTransactionTypes: (params)=> {
        // let session = localStorage.getItem('_SESSION');
        return axios.post('/transaction-types',
        params, {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    viewTransactionType: (id)=> {
        return axios.get('/transaction-type/' + id,
         {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    createTransactionType: (params)=> {
        return axios.post('/transaction-type',
        params, {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    updateTransactionType: (params, id)=> {
        return axios.put('/transaction-type/' + id,
        params, {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
    deleteTransactionType: (id)=> {
        return axios.delete('/transaction-type/' + id,
        {
            headers: {
                "Authorization" : localStorage.getItem('_SESSION')
            }
        });
    },
}

export default mxnTransactionTypes;
