<template>
  <div id="app">
    <router-view />
  </div>

</template>

<script>
export default {
  name: 'app',  
  mounted() {  
    document.title = "Global Clinic";  
  },
}
/* import Login from '@/components/Login'

export default {
  props: {
    isLogged: false
  },
  components: {
    Login
  }
} */
</script> 
