// import mxnAudit from '@/mixins/audit'
import Departments from './../Departments'
import Branches from './../Branches'
import Categories from './../Categories'
import TransactionTypes from './../TransactionTypes'
import moment from 'moment';

export default {
    name: 'Configuration',
    data() {
        return {
            tab: 'departments',
            categoryList: [],
            departmentList: [],
            branchList: []
        }
    },
    methods: {
       changeTab(tab) {
        this.tab = tab
       },
       updateDepartmentList(data) {
        // console.info(data)
        this.departmentList = data
       },
       updateBranchtList(data) {
        // console.info(data)
        this.branchList = data
       },
       updateCategoryList(data) {
        // console.info(data)
        this.categoryList = data
       },
    },
    mounted () {
        let _this = this;
    },
    components: {
        Departments,
        Branches,
        Categories,
        TransactionTypes
    }
}
