import Vue from 'vue';
import moment from 'moment'

Vue.filter('date', function(value, hasFomat) {
    let format = hasFomat || 'MMMM DD, YYYY hh:mmA';
    if (!isNaN(value)) {
        return moment(parseFloat(value)).format(format)
    } 
    
    return moment(new Date(value)).format(format);
})

Vue.filter('currency', function (value) {
    let n = typeof value === 'string' || n instanceof String ? parseFloat(value) : value;
    if (n === null || n === undefined) {
        n = 0;
    }
    return n.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
});