import mxnAudit from '@/mixins/audit'
import GenericTable from './../GenericTable'
import moment from 'moment';

export default {
    name: 'Audit',
    mixins: [mxnAudit],
    data() {
        return {

            // gta
            searchInput: '',
            gta: {
                title: ' Audit Logs',
                search: {
                    show: false
                },
                header: [
                    {name: "Description"},
                    {name: "Full Name"},
                    {name: "Email"},
                    {name: "Log Date"},
                    {name: "Status"},
                ],
                body: [
                    {name: "log_description", type: 'text'},
                    {name: "log_userfullname", type: 'text'},
                    {name: "log_useremail", type: 'text'},                    
                    {name: "log_timestamp", type: 'date'},
                    {name: "log_status", type: 'text'},
                ],
                query: this.getAuditLogs,
                data: {},
                size: 10,
                payload: {
                    page: 1,
                    sortField: "log_timestamp",
                    sortType: "ASC",
                    search: ''
                }
            }
        }
    },
    methods: {
        preLoader(act) {
            this.$store.dispatch('doPreLoader',act)
        },
        getAuditLogs() {
            let _this = this;
            let params = _this.gta.payload;
            // TODO: preloader
            _this.preLoader(true);

            mxnAudit.doGetAuditLogs(params).then(r => {
                _this.gta.data = r.data
                // TODO: end preloader
                setTimeout(() => {
                    _this.preLoader(false);
                }, 100)
            }, e => {
                console.info(e)
            })
        }
    },
    mounted () {
        let _this = this;
        // TODO: load userlist
        _this.getAuditLogs();
    },
    components: {
        GenericTable
    }
}
