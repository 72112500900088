import mxnCategories from '@/mixins/categories'
import mxnSwal from '@/mixins/toast'
import mxnCommon from '@/mixins/common'
import moment from 'moment';

export default {
    name: 'Categories',
    mixins: [mxnCategories, mxnSwal, mxnCommon],

    data() {
        return {
            categoryId: '',
            category: '',
            isOpen: false,
            // gta
            categoryList: [],
        }
    },
    methods: {
        preLoader(act) {
            this.$store.dispatch('doPreLoader',act)
        },
        getCategoryList(str) {
            let _this = this;
            // TODO: preloader
            _this.preLoader(true);

            mxnCategories.doGetCategory().then(r => {
                _this.categoryList = r.data
                // Update global list
                _this.$emit('update-categories', r.data)
                // TODO: end preloader
                setTimeout(() => {
                    _this.preLoader(false);
                }, 100)
            }, e => {
                console.info(e)
            })
        },
        saveCategoryForm(s) {
            let _this = this;
            // return
            let params = {
                "category_name": _this.category,
            }

            // TODO: preloader
            _this.preLoader(true);
            if (_this.categoryId) {
                // Create user
                mxnCategories.doUpdate(params, _this.categoryId).then(r => {
                    let d = r.data;
                    mxnSwal.toast('success', d)
                    _this.viewForm()
                    // TODO: reload
                    _this.getCategoryList()
                }, e => {
                    console.info(e)
                    mxnSwal.toast('error', e)
                    _this.preLoader(true);
                });

                return false;
            }
            // Create user
            mxnCategories.doCreate(params).then(r => {
                let d = r.data;
                mxnSwal.toast('success', d)
                _this.viewForm()
                // TODO: reload
                _this.getCategoryList()
            }, e => {
                console.info(e)
                mxnSwal.toast('error', e)
                _this.preLoader(true);
            })

        },
        doSubmitForm() {
            this.$refs.submitDeptForm.click()
        },
        doClearForm() {
            let _this = this;
            _this.categoryId = '';
            _this.category = '';
        },
        removeCategory(data,i) {
            let index = i;
            let _this = this;
            mxnSwal.confirmation({
                icon: 'warning',
                title: "Are you sure you want to remove '" + data.category_name + "'?",
                msg: "This action can't be undone",
                confirmed: () => {
                    _this.preLoader(true);
                    mxnCategories.doDelete(data.category_id).then(r => {
                        let d = r.data;
                        mxnSwal.toast('success', d)
                        // TODO: remove element
                        _this.$delete(_this.categoryList, index)
                        setTimeout(() => {
                            _this.preLoader(false);
                        }, 100)
                    }, e => {
                        console.info(e)
                        mxnSwal.toast('error', e)
                        _this.preLoader(true);
                    })
                }
            } )
            
        },
        updateCategory(d) {
            let _this = this;
            _this.viewForm();
            _this.categoryId = d.category_id
            _this.category = d.category_name
        },
        viewForm() {
            let _this = this;
            _this.doClearForm();
            _this.isOpen = !_this.isOpen
        }
    },
    mounted () {
        let _this = this;
        // TODO: load category list
        _this.getCategoryList();

    }
}
